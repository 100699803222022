import React,{ useState ,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import './Aboutus.css';
import Ads from './Ads';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../Assets/homeimg', false, /\.(png|jpe?g|svg)$/));

export default function Aboutus(props) {


  const baseurlapi=props.baseurlapi;
  const [AllReviewer, setAllReviewer] = useState([]);
  const { pathname } = useLocation();
  const [ads, setads] = useState([]);
  useEffect(() => {
    axios.get(baseurlapi+"/Reviewer.php")
    .then(response2 => {
      setAllReviewer(response2.data.AllReviewer)
    })

    axios.get(baseurlapi+"/Ads.php?type=About")
    .then(response2 => {
           setads(response2.data.Ads)
    })
     window.scrollTo(0, 0);
  }, [pathname]);


    return (
        <>
        <div className="container-fluid  p-b-50">
        <div className="container2 pt-5">
         <div className="row">
          <div className="col-md-6">
           <h1 className="fw-bold">The secret of getting ahead is <span className="color868686">getting started</span></h1>
           <img src={images['about1.png']} className="mt-4 aboutimg2 b-r-30" />
          </div>
          <div className="col-md-6">
          <p className="fw-bold">"Libraries store the energy that fuels the imagination. They open up windows to the world and inspire us to explore and achieve, and contribute to improving our quality of life."</p>
          <h1 className="mt-5 fw-bold">The Vision</h1>
          <img src={images['leftcomma.png']} style={{height:'30px'}}/>
          <p className="color868686 fw-bold mt-4" style={{fontSize:'20px'}}>Our vision at Storiesbychildren.com is to build a global community of young
          writers and readers where children&#39;s voices and perspectives are valued, celebrated, and heard, and
          where every child has the opportunity to develop their writing skills, showcase their creativity, and
          share their stories with the world.</p>
          <img src={images['rightcomma.png']} className="floatright" style={{height:'30px'}}/>
          <br/>
          <p className="mt-3 PoppinsLight f-s-15 fw-bold">Our vision is to be a comprehensive leading Writing Community worldwide – For children of age 7-
          18 years and be the pioneer of Children’s Literature.</p>
          <p className="PoppinsLight f-s-15 fw-bold"></p>

          </div>
         </div>
        </div>

        <div className="container2 mt-3">
        <h1 className="fw-bold">The Mission</h1>
        <p className="fw-bold-500 PoppinsLight">&quot;Our mission at Storiesbychildren.com is to empower and inspire the next
        generation of young writers to express themselves and share their unique perspectives through the
        written word while promoting literacy, encouraging creativity, and fostering a love of learning
        through the power of storytelling.&quot;</p>
        <div className="row mt-3">
         <div className="col-md-6">
          <p className="fw-bold">How we will do it: The Purpose</p>
          <ul className="bullets">
            
            <li className="fw-bold">
            <div style={{display: 'flex'}}>
               <div>
                <img src={images['dot.png']} height={20} />
                </div>
                <div className="m-l-10">
                Aiming to complete untold and incomplete stories.
                </div>
             </div>
               <div className="lispan PoppinsLight ">
               No story should be left unfinished. No creativity and imagination be nipped in the buds.
               </div>
            </li><br/>

            <li className="fw-bold">
            <div style={{display: 'flex'}}>
               <div>
                <img src={images['dot.png']} height={20} />
                </div>
                <div className="m-l-10">
                  To be the catalyst for their imagination and help them to share it with the world through their stories.
                </div>
             </div>
               <div className="lispan PoppinsLight ">
               Bring their imagination to the world to be read and appreciated.
               </div>
            </li><br/>

            <li className="fw-bold">
            <div style={{display: 'flex'}}>
               <div>
                <img src={images['dot.png']} height={20} />
                </div>
                <div className="m-l-10">
                To open the unbound possibilities in a child to express creativity in words.
                </div>
             </div>
               <div className="lispan PoppinsLight ">
               Facilitate their creativity and imagination by opening their minds to new ideas and thoughts.
               </div>
            </li><br/>
            <li className="fw-bold">
            <div style={{display: 'flex'}}>
               <div>
                <img src={images['dot.png']} height={20} />
                </div>
                <div className="m-l-10">
                To build and store memories of their childhood.
                </div>
             </div>
               <div className="lispan PoppinsLight ">
               Let not their skills of writing be confined to the shelves and slowly lost in time.
               </div>
            </li><br/>
            <li className="fw-bold">
            <div style={{display: 'flex'}}>
               <div>
                <img src={images['dot.png']} height={20} />
                </div>
                <div className="m-l-10">
                To reinforce the habit of reading and writing among children.
                </div>
             </div>
               <div className="lispan PoppinsLight ">
               To improve mental health and bring optimism and joyfulness in children.
               </div>
            </li><br/>
          
          
          </ul>
       
         </div>
         <div className="col-md-6">
           <img className="aboutimg2 b-r-30" src={images['about2.png']} />
         </div>
        </div>
        </div>

        <div className="container2 ">
        <h1 className="fw-bold">About Us</h1>
        <div className="row mt-3">
          <div className="col-md-12">
            <p className="PoppinsLight f-s-15 fw-bold-500">At ‘StoriesByChildren’, we are all about children and Children’s Literature.</p>
            <p className="PoppinsLight f-s-15 fw-bold-500">Welcome to Stories by Children, a platform created for young writers to share their original works
            and for readers to discover new and exciting stories written by children from all over the world.</p>
            <p className="PoppinsLight f-s-15 fw-bold-500">Love for stories is eternal, be it kids or adults. We all have been growing up hearing grandma’s
                stories. Our nights are beautiful because of bedtime stories. The stories inspire us, teach us,
                enlighten us and entertain us.
            </p>
            <p className="PoppinsLight f-s-15 fw-bold-500">There are countless stories for children written from ages till today. It may be Moral stories of Jataka
              tales, Panchatantra, Bedtime stories, or fairy tales of Snow White and the Seven Dwarfs, Cinderella,
              Alice in Wonderland, Mystery stories like Secret Seven, Famous Five, and Nancy Drew – kids love
              them all, and have been part of their childhood.
              </p>
              <p className="PoppinsLight f-s-15 fw-bold-500">Children today are not just readers or listeners but also very good storytellers and writers. The
                imagination and creativity they carry are unthinkable. We at ‘Stories By Children’ harness this
                creativity and writing skill and provide them with a free, safe, and easily accessible platform to
                submit their short stories, stories, poems, articles, Novels, and much more. ‘Stories By Children’
                encourages the children to come together as a Young Writing Community and bring their best works
                for the world to read.
                </p>
                <p className="PoppinsLight f-s-15 fw-bold-500">We believe that every child has a unique voice and vision, and we strive to create a community
                where young writers can feel heard, supported, and encouraged. Our mission is to provide an
                environment where children can develop their writing skills, build self-confidence, and have fun
                expressing themselves creatively.</p>
                <p  className="PoppinsLight f-s-15 fw-bold-500">StoriesByChildren.com is a platform dedicated to showcasing young authors&#39; incredible imagination
                and creativity worldwide. Our mission is to empower children and encourage their love for
                storytelling through the publication of their short stories. We believe that every child has a unique
                voice, and our goal is to provide a space for that voice to be heard.</p>
                <p className="PoppinsLight f-s-15 fw-bold-500">Founded in November 2020, Storiesbychildren.com is run by a team of dedicated educators and
                writers who are passionate about fostering a love of creative writing in children. Our team reviews
                each story carefully and provides constructive feedback to help young writers improve their craft.
                We also host writing workshops, story prompts, and other resources to help children develop their
                writing Skills.</p>
                <p className="PoppinsLight f-s-15 fw-bold-500">We are a community that values inclusivity, creativity, and the power of imagination. We are
                committed to promoting the works of young writers, providing a platform for their voices, and
                encouraging them to continue writing and creating.</p>
                <p className="PoppinsLight f-s-15 fw-bold-500">We are here to grow this online community of Young Authors connecting them to the world. It is
                  also our endeavor to publish their beautiful works into books. Our biggest dream is to bring out the
                  best authors into this world.</p>
                  
                  <p className="PoppinsLight f-s-15 fw-bold-500">To keep this community growing, we appreciate your efforts in sharing this platform and bringing in
                  more readers to appreciate children’s literature. By telling about ‘Stories By Children’ to your friends
                  and family, you are not only encouraging a writer but also supporting us in our vision of reaching out
                  to more kids all over the world.
                  </p>
                  <p className="PoppinsLight f-s-15 fw-bold-500">We thank you for being a Writer, Reader, and Supporter of this community. We thank you for your
                    love and support.
                  </p>
                  <p className="PoppinsLight f-s-15 fw-bold-500">Do spread the word to the world.</p>
           
          </div>
         
        </div>
        </div>

        <div className="container2 mt-2 meetteamsection" >
        <div className="row bgFEEDB5 aboutheight border-radius-25 p-d-10">
            <h4 className="text-center fw-bold mt-4 mb-4">Meet Our Team</h4>
            <div className="col-md-3 ">
            <div className="card mb-4 b-none  border-radius-25">
                <img className="card-img-top teamimg" src={images['Tanmayee.jpg']} alt="Card image cap"/>
                <div className="card-body  pd-10">
                <h6 className="card-title fw-bold">Tanmayee R Kalebar</h6>
                <p className="m-b-none">Founder - Director </p>
                <p className="m-b-none"> MBA - Finance</p>
               
                </div>
            </div>
            </div>
            <div className="col-md-3 ">
            <div className="card mb-4 b-none  border-radius-25">
                <img className="card-img-top teamimg" src={images['kashish.jpeg']} alt="Card image cap"/>
                <div className="card-body  pd-10">
                <h6 className="card-title fw-bold">Kashish Lewis</h6>
                <p className="m-b-none">Bachelors in English Literature </p>
                <p className="m-b-none">Business Partner - Strategy</p>

    
                </div>
            </div>
            </div>
            <div className="col-md-3 ">
            <div className="card mb-4 b-none  border-radius-25">
                <img className="card-img-top teamimg" src={images['Rajiv.png']} alt="Card image cap"/>
                <div className="card-body  pd-10">
                <h6 className="card-title fw-bold">Rajiv U Kalebar</h6>
                <p className="m-b-none">Head - Marketing </p>
                <p className="m-b-none">MBA - Finance and Marketing</p>
               
                </div>
            </div>
            </div>
            <div className="col-md-3 ">
            <div className="card mb-4 b-none  border-radius-25">
                <img className="card-img-top teamimg" src={images['Brosnan.jpg']} alt="Card image cap"/>
                <div className="card-body  pd-10">
                <h6 className="card-title fw-bold">Brosnan Antony</h6>
                <p className="m-b-none">HR and Creative Head </p>
                <p className="m-b-none">(BBA) - Human Resource</p>
               
                </div>
            </div>
            </div>
            <div className="col-md-3 ">
            <div className="card mb-4 b-none  border-radius-25">
                <img className="card-img-top teamimg" src={images['shivani.jpg']} alt="Card image cap"/>
                <div className="card-body  pd-10">
                <h6 className="card-title fw-bold">Shivani Kajaria</h6>
                <p className="m-b-none">Head - Administration </p>
                <p className="m-b-none"> M A</p>
               
                </div>
            </div>
            </div>
            
        </div>
        </div>
        <div className="container2 mt-2">
            <div className="text-center">
              <img src={images['serviceicon.png']} style={{height:'50px'}} />
              <h4 className="text-center fw-bold ">Our Reviewers</h4>
            </div>
         <div className="row mt-2">
         
         {AllReviewer.map((rdata,index)=>(
          <div className="col-md-2 text-center mt-5" key={index}>
          <img src={rdata.filename} className="reviewerprifile b-r-10P"  />
          <h6 className="card-title fw-bold mt-4">{rdata.FullName}</h6>
          <p className="m-b-none f-s-13 PoppinsLight">{rdata.Qualification}</p>
          <p className="m-b-none f-s-13 PoppinsLight">{rdata.Position}</p>
          </div>
         ))}

         </div>
        
        </div>

        <div className="container2 mt-4">
            <div className="text-center">
              <img src={images['serviceicon.png']} style={{height:'50px'}} />
              <h4 className="text-center fw-bold ">Publishing Partner</h4>
            </div>

         <div className="row mt-2">
         <div className="col-md-4 text-center mt-5"></div>
          <div className="col-md-2 text-center mt-5">
          <img src={images['Inkfeathers Logo.png']} className="reviewerprifile b-r-10P"  />
          <h6 className="card-title fw-bold mt-4"> INKFEATHERS PUBLISHING HOUSE</h6>
          <p className="m-b-none f-s-13 PoppinsLight">NEW DELHI</p>
          {/* <p className="m-b-none f-s-13 PoppinsLight">{rdata.Position}</p> */}
          </div>

          <div className="col-md-2 text-center mt-5">
          <img src={images['FanatiXx Gold Logo.png']} className="reviewerprifile b-r-10P"  />
          <h6 className="card-title fw-bold mt-4"> FanatiXx Publishing</h6>
          <p className="m-b-none f-s-13 PoppinsLight">ODISHA</p>
          {/* <p className="m-b-none f-s-13 PoppinsLight">{rdata.Position}</p> */}
          </div>


          <div className="col-md-5 text-center mt-5"></div>
         </div>


        
        </div>

        <Ads adsarr={ads}/>
        </div>
        </>
    )
}